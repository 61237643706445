

















































































import { WatchLoading } from "@/decorators/Loading";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { PlayerPaymentViewItem } from "@/types/Payment";
import { Header } from "@/types/Table";
import {
  CheckoutLineItem,
  GetCheckoutSession,
  GetCheckoutSessionResult,
  Transaction
} from "@sportango/backend";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import Component, { mixins } from "vue-class-component";
import Fuse from "fuse.js";
import { AutoCompleteItem } from "@/components/Inputs/mixins";
import { httpsCallable } from "@firebase/functions";
import { Functions } from "@/firebase";
import { SharedPaymentIntents, SharedPaymentMethods } from "@/mixins/Payments";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import {
  SportangoSelect,
  SportangoTextField
} from "@/components/Inputs/overrides";
@Component({
  name: "profile-payments-report",
  components: {
    SportangoSelect,
    SportangoTextField,
    TablePaymentMethod,
    PaymentStatus
  }
})
export default class ProfilePaymentsReport extends mixins(
  CurrentUserMixin,
  LoadingMixin,
  SharedPaymentIntents,
  SharedPaymentMethods
) {
  selectedCustomerTransactions: Array<PlayerPaymentViewItem> = [];
  searchText = "";
  selectedProgram = "";

  get headers(): Array<Header<PlayerPaymentViewItem>> {
    return [
      {
        value: "itemName",
        text: "Name",
        sortable: true,
        align: "start"
      },
      {
        value: "playerName",
        text: "Profile",
        sortable: true,
        align: "start"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "120px"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        align: "center",
        sortable: false,
        text: "Status"
      },
      {
        value: "paymentMethod",
        text: "Payment Method",
        sortable: false,
        align: "center",
        width: "150px"
      },
      {
        value: "paymentDateShort",
        text: "Date",
        sortable: true,
        align: "center"
      }
    ];
  }

  get items(): Array<PlayerPaymentViewItem> {
    const returnValue: Array<PlayerPaymentViewItem> = [];
    this.transactions.forEach((t, transactionIndex) => {
      t.customers?.forEach((customerInfo) => {
        const customerPaymentIntent = this.paymentIntents.find(
          (p) => p.id === customerInfo.paymentIntentId
        );
        const { status, statusMessage } = getCorrectStatusAndMessage(
          customerInfo,
          customerPaymentIntent
        );
        const { short, full } = parsePaymentDate(
          customerPaymentIntent?.created,
          customerInfo
        );
        const result = {
          transactionId: t.id || "",
          itemName: t.description || "Payment",
          playerName:
            this.profiles.find((p) => p.uid === customerInfo.uid)
              ?.displayName || "",
          itemLink: t.parentItem || "",
          amount: customerInfo.amount || 0,
          convenienceFee: getRightConvenienceFee(
            status,
            customerInfo.amount || 0,
            null,
            customerInfo,
            this.$store.getters.merchantInfo
          ),
          total: customerInfo.amount || 0,
          status,
          statusMessage,
          disabled:
            status === "success" ||
            status === "processing" ||
            status === "cancelled",
          isPaymentRunning: false,
          uid: customerInfo.uid || "",
          paymentMethod: customerPaymentIntent
            ? this.paymentMethods.find(
                (p) => p?.id === getRightPaymentMethod(customerPaymentIntent)
              ) || null
            : null,
          paymentDate: full,
          paymentDateShort: short,
          parent: t.parentItem || "",
          date:
            (customerInfo.paidInCash
              ? customerInfo.cashPaidDate
              : (customerPaymentIntent?.created || 0) * 1000) || 0,
          itemId: `${customerInfo}-${transactionIndex}`,
          paidInCash: customerInfo.paidInCash
        };
        result.total = result.amount + result.convenienceFee;
        returnValue.push(result);
      });
    });
    return returnValue;
  }

  get filteredItems(): Array<PlayerPaymentViewItem> {
    let items = this.items;
    if (this.selectedProgram && this.selectedProgram.length > 0) {
      items = items.filter((i) => i.parent === this.selectedProgram);
    }
    if (this.searchText.trim().length >= 2) {
      const fuse = new Fuse<PlayerPaymentViewItem>(items, {
        keys: ["itemName", "amount", "total", "paymentDateShort"],
        minMatchCharLength: 2,
        threshold: 0.3
      });
      items = fuse.search(this.searchText).map((r) => r.item);
    }
    return items.sort((a, b) => b.date - a.date);
  }

  get shouldAllowCheckout(): boolean {
    if (this.$store.getters.merchantInfo?.merchantId) {
      if (this.items.filter((i) => !i.disabled).length > 0) {
        if (this.currentUser?.stripeCustomerId) {
          return true;
        }
      }
    }
    return false;
  }

  get programItems(): Array<AutoCompleteItem | undefined> {
    const programs = this.transactions.map((t) => t.parentItem);
    return [
      {
        text: "All",
        value: ""
      },
      ...this.$store.getters.programs
        .filter((p) => programs.includes(p.id))
        .map((p) => {
          return {
            text: p.name || "",
            value: p.id || ""
          };
        })
    ];
  }

  get transactions(): Array<Transaction> {
    return this.$store.getters.transactions.map((t) => {
      t.customers = t.customers?.filter((c) =>
        c.uid?.includes(this.currentUser?.uid || "")
      );
      return t;
    });
  }

  isPaymentMethodLoad(item?: PlayerPaymentViewItem) {
    if (item !== undefined) {
      if (item.paymentMethod === null) {
        if (this.paymentMethods.length === 0) {
          return false;
        }
        return true;
      }
    }
    return true;
  }

  async checkout() {
    const lineItems: Array<CheckoutLineItem> = [];
    const transactionMap: { [x: string]: string } = {};
    this.items
      .filter((i) => !i.disabled)
      .forEach((item) => {
        lineItems.push({
          amount: item.amount * 100,
          description: item.itemName,
          name:
            this.$store.getters.programs.find((p) => p.id === item.parent)
              ?.name || "",
          quantity: 1
        });
        transactionMap[item.transactionId] = item.uid;
      });
    const data = (
      await httpsCallable<GetCheckoutSession, GetCheckoutSessionResult>(
        Functions,
        "createCheckoutSession"
      )({
        cancelUrl: location.href,
        successUrl: location.href,
        customer: this.currentUser?.stripeCustomerId || "",
        merchantId: this.$store.getters.merchantInfo?.merchantId || "",
        transactions: transactionMap,
        lineItems
      })
    ).data;
    window.location.href = data.url;
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getTransactionsForUser", this.currentUser?.uid);
    await this.getPaymentIntents(this.transactions);
    await this.getPaymentMethodsForIntents(this.paymentIntents);
  }
}
